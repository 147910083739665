import {Component, OnInit} from '@angular/core';
import {UserLogged} from "../../../services/user-logged";
import {User} from "../../../models/user/user";
import {Router} from "@angular/router";
import {PaymentService} from "../../../services/payment.service";
import {CoinBuy} from "../../../models/coinBuy";
import {environment} from "../../../environments/environment";
import {loadStripe} from '@stripe/stripe-js';
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {UtilsModule} from "../../../utils";
import {NgToastService} from "ng-angular-popup";
import {LoginService} from "../../../services/login.service";
import {ControllerCarouselService} from "../../../services/controller-carousel.service";
import {interval, Observable} from "rxjs";
import {WebSocketAPI} from "../../../services/WebSocketAPI";
import {Notification} from "../../../models/notification";
import {PlaySoundService} from "../../../services/play-sound.service";
import {Plano} from "../../../models/plano";
import {PlanoModel} from "../../../models/planoModel";


@Component({
  selector: 'app-drop-user',
  templateUrl: './drop-user.component.html',
  styleUrls: ['./drop-user.component.scss', '../../../../darkmode.scss']
})
export class DropUserComponent implements OnInit {

  public isLogged: boolean = false;
  public user: User;
  public coinBuy: CoinBuy[] | undefined;
  public state:boolean=false;
  public flagNotification:number = 0;
  public unreadMessages:number = 0;
  public nameUser:string = "";
  public showMenuMobLog: boolean = false;
  public planos: Plano[] = new Array(new PlanoModel());
  public descricaoSimples: string = "";

  constructor(
    public routes: Router,
    public coinService: PaymentService,
    private coinBuyService: PaymentService,
    private route: ActivatedRoute,
    private activatedRouter:ActivatedRoute,
    private userLogged: UserLogged,
    private toast: NgToastService,
    private loginService: LoginService,
    private _controllerCarouselService: ControllerCarouselService,
    private soundService:PlaySoundService,
    private webSocketApi:WebSocketAPI,
    private apiService:ApiService,
  ) {
    this.loadPlanos();
    this.user = userLogged.user;
    this.nameUser = this.user.name.split(" ")[0];
    if(this.user.notifications == null)
      this.user.notifications = new Array<Notification>();
    this.unreadMessages = parseInt(<string> localStorage.getItem("unreadMessages"));
    if(isNaN(this.unreadMessages))
      this.unreadMessages = 0;
    this.user.notifications = this.user.notifications.filter(n => n.readed == this.flagNotification)
    this.isLogged = userLogged.isLogged;
    this._controllerCarouselService.state.subscribe(s => this.state = s);
    if(this.userLogged.isLogged) {
      this.webSocketApi._connectNotifications();
      this.webSocketApi._connectNewMessagesConversations();
    }
  }

  async loadPlanos() {
    this.planos = await this.apiService.get(ApiService.URL + 'coinbuy/planos').toPromise();
  }

  comprar(plano:Plano) {
    if(localStorage.getItem('plano')){
      localStorage.removeItem('plano');
    }
    localStorage.setItem('plano', JSON.stringify(plano))
    this.routes.navigate(['checkout/plano']);
  }

  ngOnInit(): void {
    this.loadCoin();
    let _this = this;
    let user = JSON.parse(<string>localStorage.getItem("user"));
    if(user != null && user.darkMode == true){
      document.documentElement.setAttribute('data-theme', 'dark');
    }
    if(this.user.id != null) {
      setTimeout(() => {
        interval(6000).subscribe((val: number) => {
          this.webSocketApi.checkNewNotifications(this.user.id);
        });
      },1000);
      this.webSocketApi.notification.subscribe(n => {
        // @ts-ignore
        if (n != null && this.user.notifications.length != n.length && n.length > 0) {
          this.user.notifications = n;
          localStorage.setItem("user", JSON.stringify(this.user));
          if(n.length > this.user.notifications.length)
            this.soundService.play();
        }
        if(n == null || n.length == 0) {
          this.user.notifications = [];
          localStorage.setItem("user", JSON.stringify(this.user));
        }
      });
      setTimeout(() => {
        interval(5000).subscribe((val: number) => {
          this.webSocketApi.checkNewMessagesConversation(this.user.id);
        });
      },1000);
      this.webSocketApi.unreadMessages.subscribe(unread => {
        // @ts-ignore
        if (unread > _this.unreadMessages) {
          _this.unreadMessages = unread;
          localStorage.setItem("unreadMessages", unread.toString());
          // @ts-ignore
          let url = this.activatedRouter.snapshot._routerState.url;
          if(!url.includes("inbox/")) {
            this.soundService.play();
          }
        } else if (unread == 0) {
          _this.unreadMessages = unread;
          localStorage.setItem("unreadMessages", unread.toString());
        }
      });
    }
  }

  async readNotification(notificationId:number) {
    this.webSocketApi.updateReadedNotifications(notificationId);
    this.user = await this.loginService.getUser(this.user.id);
    this.user.notifications = this.user.notifications.filter(n => n.readed == this.flagNotification);
    localStorage.setItem("user", JSON.stringify(this.user));
  }

  async loadCoin() {
    this.coinBuy = await this.coinService.getList().toPromise();
  }

  public async createCheckout(coin: CoinBuy) {

    // this.coinBuyService.createCheckoutCoin(this.user, coin);
  }

  openPerfil() {
    let name = UtilsModule.accentsTidy(this.user.name.replaceAll(" ", "-")).toLowerCase();
    localStorage.removeItem("userProfile");
    window.location.href = this.routes.createUrlTree(['freelancer/' + name]).toString();
    this.routes.navigate([]);
  }

  openChat() {
    this.routes.navigate(['inbox']);
  }

  protected readonly UtilsModule = UtilsModule;

  changeOnline() {
    this.user = JSON.parse(<string>localStorage.getItem("user"));
    this.user.online = !this.user.online;
    this.userLogged.user = this.user;
    localStorage.setItem("user", JSON.stringify(this.user));
    this.loginService.updateUserOnline(this.user);
  }

  changeDarkTheme(){
    this.user.darkMode = !this.user.darkMode;
    this.loginService.updateUserViewMode(this.user);
    localStorage.setItem("user", JSON.stringify(this.user));
    this.userLogged.user = this.user;
    if(!this.user.darkMode){
      // @ts-ignore
      document.getElementById("logo").setAttribute("src", "/assets/_img/Logotipo_Medium_getjus_by_tippz.png");
      document.documentElement.setAttribute('data-theme', 'light');
    } else{
      // @ts-ignore
      document.getElementById("logo").setAttribute("src", "/assets/_img/Logotipo_Medium_white_getjus_by_tippz.png");
      document.documentElement.setAttribute('data-theme', 'dark');
    }
  }


  public changeSttMenu(){
    this.showMenuMobLog = !this.showMenuMobLog;
  }

  public imgUser(): string {
    return this.user.picture;
  };

  protected readonly alert = alert;


  public showExplorar()  {
    this._controllerCarouselService.changeState(!this.state);
  }

  logout() {
    this.loginService.logout();
  }

  public getDescricaoSimples(plano: Plano): string {
    if(plano.nome === "Plano Básico"){
      this.descricaoSimples = "Ideal para quem está começando na Tippz.";
    }
    if(plano.nome === "Plano Premium"){
      this.descricaoSimples = "Ideal para quem já tem uma área de atuação consolidada.";
    }
    if(plano.nome === "Plano Profissional"){
      this.descricaoSimples = "Ideal para quem envia muitos orçamento.";
    }
    return this.descricaoSimples;
  }

}
