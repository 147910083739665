export const environment = {
  production: true,
  appURL: 'https://getjus.com.br/',
  apiEndpoint: 'https://api.tippz.com.br/api/',
  itensPerPage: 10,
  waitLoader: 500,
  stripe: 'pk_live_51N3IvYCOrr5oBoiLSMzjrsKieKAsR0QJlvRJYgorkcd4UUB4JuhDAIDdk1ZsWg4rzGLIIa284ZEFPhDor0Qcr86e00Uib1qvon',
  googleClientId: '356056662198-ud0uqck8spmuq5l1g5k59g7vpnkfaoca.apps.googleusercontent.com',
  googleApiKey: 'AIzaSyB7actDOLDXyVDTE033fsorsPlfVbZm7kI',
  FACEBOOK_APP_KEY: '901732401059904'
};
