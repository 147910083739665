import {Categoria} from "./categoria";
import {Sistema} from "../sistem.enum";

export class CategoriaModel implements Categoria {
  id!: number;
  nome!: string;
  icone!: string;
  nivel!: number;
  categoriaPaiNome!: string;
  categoriaPaiId!: number;
  filhas!:Categoria[];
  expanded!:boolean;
  lance!:number;
  descricao!:string;
  geolocalizacao!:boolean;
  tipoCategoria!:number;
  sistema:number = Sistema.GETJUS;
}
