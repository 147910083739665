import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Categoria} from "../models/categoria/categoria";
import {Sistema} from "../models/sistem.enum";

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  private urlCategoria = ApiService.URL + "categoria/";

  constructor(
    private service: ApiService
  ) { }

  public getByNivel(nivel:number, tipo:number=-1) {
    return this.service.get(`${this.urlCategoria}nivel/${nivel}/${tipo}/${Sistema.GETJUS}`);
  }
  public getByCategoriaPai(idPai:number) {
    return this.service.get(this.urlCategoria + "pai/" + idPai);
  }
  public async getPopulares() {
    return await this.service.get(`${this.urlCategoria}populares/${Sistema.GETJUS}`).toPromise();
  }
  public getAll() {
    return this.service.get(this.urlCategoria);
  }
  public async save(categoria:Categoria) {
    return await this.service.post(this.urlCategoria, categoria).toPromise();
  }
  public delete(id:number) {
    return this.service.delete(this.urlCategoria + id).toPromise();
  }
}
